<template>
    <popup v-model="modal" title="取消繳費" width="500" spacing titleCenter
        btnLeft="關閉" colorLeft="grey" @clickLeft="modal = false"
        btnRight="確定" colorRight="main-color" @clickRight="checkDeleteOrder">
        <div
            class="text-center letter-3"
            :class="isXS ? ' text-h7' : ' text-h6'"
            v-text="'確定取消此次繳費？'" />
    </popup>
</template>

<script>
import popup from "./popup.vue"
import { mapGetters, mapActions } from "vuex"

export default {
    model: { prop: 'show', event: 'switchModal'},
    props: {
        show: { type: Boolean, default: false }
    },
    components: { popup },
    computed: {
        ...mapGetters(["tickets"]),
        modal: {
            get() {
                return this.show
            },
            set(value) {
                this.$emit('switchModal', value)
            }
        },
        isXS() {
            return this.$vuetify.breakpoint.xsOnly
        }
    },
    methods: {
        ...mapActions(['deleteOrder']),
        async checkDeleteOrder() {
            await this.deleteOrder()
            this.exitPrevPage()
        },
        exitPrevPage() {
            this.modal = false
            if (this.tickets.length == 0) {
                this.$router.push({name: 'selectReceipt'})
            }
        }
    }
}
</script>
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        storeSnackbar: {
            show: false,
            text: '',
            color: 'main-color'
        },
        currentRoadside: {
            name: '',
            city: '',
            site: '',
            isDash: false
        },
        userRoadId: null,
        licensePlate: '',
        sites: [],
        citys: {},
        paymentType: [],
        tickets: [],
        build_time: '',
        paymentMode: 0,
        paymentUrl: {
            web: '',
            app: ''
        },
        payments: [
            { code: 0, name: 'Zero' },
            { code: 1, name: 'LINE PAY' },
            { code: 2, name: '街口支付' },
            { code: 5, name: 'icash' },
            { code: 100, name: 'Taiwan Pay' },
            { code: 7, name: 'Apple Pay' },
            { code: 8, name: 'Easy wallet' }
        ],
        siteDisabled: false
    },
    getters: {
        storeSnackbar: state => state.storeSnackbar,
        currentRoadside: state => state.currentRoadside,
        roadsideName: state => state.currentRoadside.name,
        isDash: state => state.currentRoadside.isDash,
        site: state => state.currentRoadside.site,
        city: state => state.currentRoadside.city,
        userRoadId: state => state.userRoadId,
        sites: state => state.sites,
        citys: state => state.citys,
        paymentType: state => state.paymentType,
        licensePlate: state => state.licensePlate,
        tickets: state => state.tickets,
        build_time: state => state.build_time,
        paymentUrl: state => state.paymentUrl,
        paymentMode: state => state.paymentMode,
        payments: state => state.payments,
        siteDisabled: state => state.siteDisabled
    },
    actions: {
        setSnackbar({ commit }, data) {
            commit('snackbar', data)
        },
        closeSnackbar({ commit }) {
            commit('clearSnackbar')
        },
        async init({ state, commit }) {
            let sites = []
            let citys = []
            state.licensePlate = ""
            await axios.get('roadside/getList').then(response => {
                switch (response.data.code) {
                    case 20000: {
                        sites = response.data.payload.site
                        citys = response.data.payload.city
                        commit('setRoadsideData', { sites: sites, citys: citys })
                        break
                    }
                    case 40000:
                        commit('snackbar', { text: response.data.message, color: 'red' })
                        break
                }
            })
        },
        setRoad({ commit }, data) {
            commit('setRoad', data)
        },
        async setPaymentType({ commit }, data) {
            commit('setPaymentType', data)
        },
        setTickets({ state }, data) {
            state.licensePlate = data.licensePlate
            state.tickets = data.tickets

            let date = new Date()
            const formatNum = (num) => {
                return num < 10 ? '0' + num : num
            }
            const formatDate = (date) => {
                const year = date.getFullYear()
                const month = formatNum(date.getMonth()+1)
                const day = formatNum(date.getDate())
                const hour = formatNum(date.getHours())
                const minute = formatNum(date.getMinutes())
                const second = formatNum(date.getSeconds())
                return `${year}-${month}-${day} ${hour}:${minute}:${second}`
            }
            state.build_time = formatDate(date)
        },
        async createOrder({ commit, state }) {
            const params = {
                city: state.currentRoadside.city,
                license_plate: state.licensePlate,
                ticket_ids: state.tickets
            }
            try {
                const response = await axios.post('order/create', params)
                switch (response.data.code) {
                    case 20000:
                        commit('createOrder', response.data.payload)
                        break
                    case 40000:
                        commit('snackbar', { text: response.data.message, color: 'red' })
                        break
                }
            } catch (e) {
                commit('snackbar', { text: '建立訂單失敗，請重新嘗試。', color: 'red' })
            }
        },
        async deleteOrder({ commit, state }) {
            const params = {
                city: state.currentRoadside.city,
                roadside_order_id: state.tickets.id
            }
            try {
                const response = await axios.delete('order/delete', { data: params })
                switch (response.data.code) {
                    case 20000:
                        state.tickets = []
                        break
                    case 40000:
                        commit('snackbar', { text: response.data.message, color: 'red' })
                        break
                }
            } catch (e) {
                commit('snackbar', { text: '刪除訂單失敗。', color: 'red' })
            }
        },
        setPaymentUrl({ commit }, data) {
            commit('setPaymentUrl', data)
        },
        clearInfo({ state }) {
            state.currentRoadside = {
                name: '',
                city: '',
                site: '',
                isDash: false
            }
            state.sites = []
            state.citys = {}
            state.licensePlate = ''
            state.paymentMode = ''
            state.paymentUrl = { app: '', web: '' }
            state.tickets = []
        },
        setSiteDisabled({ state }, bool) {
            state.siteDisabled = bool
        }
    },
    mutations: {
        snackbar(state, data) {
            state.storeSnackbar = {
                show: true,
                text: data.text,
                color: data.color || 'main-color'
            }
        },
        clearSnackbar(state) {
            state.storeSnackbar = {
                show: false,
                text: '',
                color: 'main-color'
            }
        },
        setRoadsideData(state, data) {
            state.sites = data.sites
            state.citys = data.citys
        },
        setRoad(state, data) {
            if (!state.siteDisabled) {
                state.userRoadId = data.id
            }
            const id = data.id
            const roadsideName = _.find(state.sites, { 'id': id }).roadside_name
            const cityData = state.citys[id][data.isToday ? 'today' : 'history']
            state.currentRoadside = {
                name: roadsideName,
                city: cityData.city_id,
                site: cityData.site_id,
                isDash: cityData.isDash,
                isToday: data.isToday
            }
        },
        setPaymentType(state, data) {
            state.paymentType = data
        },
        setPaymentUrl(state, data) {
            state.paymentMode = data.paymentMode
            state.paymentUrl = data.paymentUrl
        },
        createOrder(state, data) {
            state.tickets = data
        }
    },
    modules: {},
    plugins: [new VuexPersistence().plugin]
})

export default store

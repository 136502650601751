<template>
    <v-sheet color="white" elevation="2" :width="width" rounded="xl"
        class="py-5 px-3" style="position: relative">
        <div class="payment-info-shadow"/>
        <div class="payment-info-border"/>
        <slot/>
        <v-row class="pa-4">
            <template v-for="(data, index) in info">
                <v-col cols="5" class="text-left pt-1" :key="`field-${index}`" v-text="data.field" />
                <v-col cols="7" class="text-right" :key="`value-${index}`" v-text="data.value" />
            </template>
        </v-row>
    </v-sheet>
</template>

<script>
export default {
    props: {
        info: { type: Array, default: [] },
        width: { type: String, default: '100%' }
    }
}
</script>

<style scoped>

.col-5, .col-7 {
    padding: 8px;
}
.payment-info-shadow, .payment-info-border {
    width: 100%;
    height: 102%;
    position: absolute;
    border-radius: 24px;
}

.payment-info-border {
    border: 1px solid #C8C2A6A1;
    top: -9px;
    left: -10px;
    z-index: -1;
}

.payment-info-shadow {
    background: #C8C2A6A1;
    top: -14px;
    left: 14px;
    z-index: -1;
}
</style>
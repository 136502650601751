<template>
    <div class="text-center">
        <!-- payment results title -->
        <div class="text-center" :class="completePayment ? '' : 'pb-5'">
            <h1 class="payment-reults pb-3"
                :style="{'margin-top': !!goToHomeBtnText ? '0' : '20px'}"
                :class="`${completePayment ? 'bronzing' : 'error'}--text`"
                v-text="title" />
            <span v-if="text" v-text="text" />
        </div>

        <!-- fails -->
        <info-bar-bronzing v-if="!completePayment"
            :info="paymentInfo" :width="isMobile ? '100%' : '60%'" class="mt-3 mb-8 mx-auto">
            <h3 class="text-center py-3">繳費資料</h3>
            <div class="dialog-divider mb-4" style="position: relative; top: 0;"/>
        </info-bar-bronzing>

        <!-- success -->
        <div v-else>
            <infoBar :info="paymentInfo" />
            <receipt class="text-left mb-2" :list="tickets.tickets" :pc-height="600" :phone-add-height="80" />
            <v-row v-if="!isMobile" class="mt-3">
                <v-col cols="6" class="d-flex align-end">
                    <info-bar-bronzing :info="paymentInfo"/>
                </v-col>
                <v-col cols="6" class="d-flex align-end justify-end">
                    <h3>總金額</h3>
                    <h3 class="pl-8">$ {{tickets.total_fee.toLocaleString()}}</h3>
                </v-col>
            </v-row>
        </div>

        <v-btn v-if="goToHomeBtnText && !(completePayment || isMobile)" @click="gotoHome"
            :class="isMobile ?'white':' mt-10'" outlined color="secondary-color" elevation="3" class="select-btn">
            <span v-text="goToHomeBtnText" />
        </v-btn>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import receipt from "./elements/receipt.vue"
import infoBarBronzing from './elements/info-bar-bronzing.vue'
import infoBar from './elements/info-bar.vue'

export default {
    data() {
        return {
            completePayment: false,
            order_id: '',
            title: '確認付款中',
            text: '請稍後',
            retry: 0,
            goToHomeBtnText: ''
        }
    },
    components: { receipt, infoBarBronzing, infoBar },
    computed: {
        ...mapGetters(['city', "roadsideName", "tickets", 'licensePlate', 'build_time', 'paymentMode', 'payments', 'paymentUrl', 'currentRoadside']),
        payment_mode() {
            return _.find(this.payments, {'code': this.paymentMode})?.name
        },
        paymentInfo() {
            if (!this.completePayment) {
                return [
                    {field: '車牌號碼', value: this.licensePlate},
                    {field: '停車路段', value: this.roadsideName},
                    {field: '訂單成立時間', value: this.build_time},
                    {field: '繳費方式', value: this.payment_mode},
                    {field: '總金額', value:  '$ ' + this.tickets.total_fee?.toLocaleString()}
                ]
            } else {
                return [
                    {field: '停車路段', value: this.roadsideName},
                    {field: '訂單成立時間', value: this.build_time},
                    {field: '繳費方式', value: this.payment_mode}
                ]
            }
        },
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown
        }
    },
    created() {
        this.order_id = this.$route.query.orderId
        if (!this.tickets.id || !this.order_id) {
            this.$router.push({name: 'parking'})
        }
        setTimeout(() => {
            this.confirmPayment()
        }, 500)
    },
    methods: {
        ...mapActions(['setSnackbar']),
        async confirmPayment() {
            if (this.order_id && this.tickets.id) {
                const params = {
                    city: this.city,
                    order_id: this.order_id
                }

                try {
                    const response = await axios.post('order/confirm-transaction', params)
                    switch (response.data.code) {
                        case 20000:
                            this.completePayment = true
                            this.title = '繳費成功'
                            this.text = this.currentRoadside.isToday ? '繳費後請於10分鐘內離場' : '';
                            this.goToHomeBtnText = '繼續繳費'
                            window.localStorage.setItem('orderResult', 'success')
                            break
                        case 40000:
                            this.title = '繳費失敗'
                            this.text = '請重新確認是否已經完成交易。'
                            this.goToHomeBtnText = '重新繳費'
                            window.localStorage.setItem('orderResult', 'error')
                            if (response.data.ui_message) {
                                this.setSnackbar({ text: response.data.message, color: 'red' })
                            }
                            break
                        case 40001:
                            if (this.retry < 10) {
                                setTimeout(() => {
                                    this.confirmPayment()
                                }, 2 ** this.try * 1000)
                                this.retry++
                            } else {
                                this.title = '繳費失敗'
                                this.text = '請重新確認是否已經完成交易。'
                                this.goToHomeBtnText = '重新繳費'
                                window.localStorage.setItem('orderResult', 'error')
                            }
                            break
                        default:
                            break
                    }
                } catch (error) {
                    this.setSnackbar({text:"網路錯誤，請重新整理", color: "red"})
                }
            } else {
                window.localStorage.setItem('orderResult', 'success')
                this.gotoHome()
            }
        },
        gotoHome() {
            delete this.$route.query.orderId
            window.location.href = window.location.origin + window.location.pathname + '#/'
        }
    }
}
</script>

<style>
.payment-reults {
    font-size: 2.8rem;
}

@media (max-width: 540px) {
    .payment-reults {
        font-size: 2rem;
    }
}

</style>

<style lang="scss" scoped>
.select-btn {
    width: 280px !important;
    height: 86px !important;
    margin-bottom: 86px;
    border-radius: 20px;

    span {
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: .2em;
        color: var(--v-secondary-color-base);
    }
}

</style>

<template>
    <popup v-model="modal" title="選擇付款方式" :width="isXS ? '400' : '60vw'" spacing titleCenter>
        <v-row :class="isMobile ? '' :'mx-6'" align="center" class="text-center">
            <v-col :cols="col" class="pointer" v-if="supportPayment(1)">
                <img :src="linePay" :width="imgWidth" alt="LINE PAY" @click="beginPayment(1)">
            </v-col>
            <v-col :cols="col" class="pointer" v-if="supportPayment(2)">
                <img :src="jkoPay" :width="imgWidth" alt="街口支付" @click="beginPayment(2)">
            </v-col>
            <v-col :cols="col" class="pointer" v-if="supportPayment(5)">
                <img :src="icash" :width="imgWidth" alt="icash" @click="beginPayment(5)">
            </v-col>
            <v-col :cols="col" class="pointer" v-if="supportPayment(100)">
                <img :src="taiwanPay" :width="imgWidth" alt="Taiwan Pay" @click="beginPayment(100)">
            </v-col>
            <v-col :cols="col" class="pointer" v-if="supportPayment(7) && canUseApplePay">
                <img :src="applePay" :width="imgWidth" alt="Apple Pay" @click="beginPayment(7)">
            </v-col>
            <v-col :cols="col" class="pointer" v-if="supportPayment(8)">
                <img :src="easyWallet" :width="imgWidth" alt="Easy wallet" @click="beginPayment(8)">
            </v-col>
        </v-row>
    </popup>
</template>

<script>
import popup from "./popup.vue"
import { mapGetters, mapActions } from "vuex"
import linePay from '../../../assets/image/pays/line-pay.png'
import jkoPay from '../../../assets/image/pays/jko-pay.svg'
import icash from '../../../assets/image/pays/icash.jpg'
import taiwanPay from '../../../assets/image/pays/taiwan-pay.png'
import applePay from '../../../assets/image/pays/apple-pay.png'
import easyWallet from '../../../assets/image/pays/easy-wallet.png'

export default {
    data() {
        return {
            canUseApplePay: false,
            axiosSource: null,
            linePay: linePay,
            jkoPay: jkoPay,
            icash: icash,
            taiwanPay: taiwanPay,
            applePay: applePay,
            easyWallet: easyWallet
        }
    },
    model: { prop: 'show', event: 'switchModal'},
    props: {
        show: { type: Boolean, default: false }
    },
    components: { popup },
    computed: {
        ...mapGetters(["tickets", "paymentType", 'site', 'roadsideName']),
        modal: {
            get() {
                return this.show
            },
            set(value) {
                this.$emit('switchModal', value)
            }
        },
        isXS() {
            return this.$vuetify.breakpoint.xsOnly
        },
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown
        },
        col() {
            return this.isMobile ? 6 : 4
        },
        imgWidth() {
            return this.isMobile ? '100%' : '150'
        }
    },
    created() {
        this.checkApplePayIsSupported()
    },
    methods: {
        ...mapActions(['deleteOrder', 'setPaymentUrl', 'setSnackbar']),
        supportPayment(code) {
            return ~this.paymentType.indexOf(code)
        },
        beginPayment(code) {
            switch (code) {
                case 0:
                    break
                case 7:
                    this.applePayment(code)
                    break
                default:
                    this.otherPayment(code)
                    break
            }
        },
        checkApplePayIsSupported(retry = 1) {
            if (retry > 0) {
                if (window.ApplePaySession) {
                    const merchantIdentifier = "merchant.com.ichenparking.staging"
                    const promise = ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier)
                    promise.then((canMakePayments) => {
                        if (canMakePayments) {
                            this.canUseApplePay = true
                        } else {
                            retry--
                            this.checkApplePayIsSupported(retry)
                        }
                    }).catch(e => {
                        retry--
                        this.checkApplePayIsSupported(retry)
                    })
                } else {
                    return false
                }
            }
        },
        async applePayment(code) {
            const site = this.site
            const _funSendPaymentToken = this.sendPaymentToken
            const _funGoPayment = this.goPayment
            const _funPaymentFailed = this.paymentFailed
            const _funSetSnackbar = this.setSnackbar
            this.axiosSource = axios.CancelToken.source()
            const cancelToken = { cancelToken: this.axiosSource.token }
            const request = {
                countryCode: "TW",
                currencyCode: "TWD",
                merchantCapabilities: ["supports3DS"],
                supportedNetworks: ["visa", "masterCard", "amex", "discover"],
                total: {
                    label: "停車費用",
                    amount: this.tickets.total_fee,
                    type: "final"
                },
                lineItems: [
                    {
                        label: this.roadsideName + "停車費用",
                        amount: this.tickets.total_fee,
                        type: "final"
                    }
                ]
            }
            const session = new ApplePaySession(9, request)
            session.begin()
            //  驗證商家
            session.onvalidatemerchant = async function(event) {
                const params = {
                    lot_id: site,
                    validation_url: event.validationURL
                }
                try {
                    const response = await axios.get('apple-pay/get-merchant', {params}, cancelToken )
                    if (response.status === 200) {
                        const responseData = JSON.stringify(response.data)
                        session.completeMerchantValidation(responseData)
                    }
                } catch (error) {
                    _funSetSnackbar({text:"網路錯誤，請重新整理", color: "red"})
                }
            }
            
            // 收到payment_token事件處理
            session.onpaymentauthorized = async function(event) {
                let result = {}
                const promise = _funSendPaymentToken(event.payment.token, code)
                promise.then(function(response) {
                    if ([1, 20000].includes(response.status)) {
                        result.status = ApplePaySession.STATUS_SUCCESS
                    } else {
                        result.status = ApplePaySession.STATUS_FAILURE
                    }
                    session.completePayment(result)
                    if ([1, 20000].includes(response.status)) {
                        if (response.payload != null) {
                            if (response.payload.web != null) {
                                setTimeout(() => { _funGoPayment(response.payload, 7) }, 1000)
                            } else {
                                _funPaymentFailed()
                            }
                        } else {
                            _funPaymentFailed()
                        }
                    } else {
                        _funSetSnackbar({text:"交易失敗，請重新嘗試", color: "red"})
                    }
                })
                .catch((_errorIgnored) => {
                    _funSetSnackbar({text:"交易失敗", color: "red"})
                })
            }

            session.oncancel = function(error) {
                this.axiosSource.cancel()
                this.axiosSource = axios.CancelToken.source()
                _funSetSnackbar({text:"取消交易", color: "red"})
            }
        },
        //*** 請求授權交易 ***/
        sendPaymentToken(paymentToken, code) {
            const params = {
                site: this.site,
                roadside_order_id: this.tickets.id,
                payment_type: code,
                payment_token: JSON.stringify(paymentToken),
                return_url: '#/complete-transaction'
            }
            return new Promise(function(resolve, reject) {
                axios.post('begin-transaction', params).then(response => {
                    if (response.status === 200) {
                        const responseData = response.data
                        resolve(responseData)
                    } else {
                        this.setSnackbar({text: "網路錯誤，請重新整理", color: "red"})
                    }
                }).catch((error) => {
                    this.setSnackbar({text: "網路錯誤，請重新整理", color: "red"})
                    reject(error)
                })
            })
        },
        async otherPayment(code) {
            const params = {
                site: this.site,
                roadside_order_id: this.tickets.id,
                payment_type: code,
                return_url: '#/complete-transaction'
            }
            try {
                const response = await axios.post('begin-transaction', params)
                const responseData = response.data
                switch (responseData.code) {
                    case 20000:
                        if (responseData.payload != null) {
                            this.goPayment(responseData.payload, code)
                        } else {
                            this.delOrderAndGoSearch('本次交易建立連結失敗，請重新選取單據計價。')
                        }
                        break
                    case 40000:
                        this.delOrderAndGoSearch(responseData.message)
                        break
                    default:
                        this.delOrderAndGoSearch('此次交易有誤，請重新選取單據計價。')
                        break
                }
            } catch (error) {
                this.setSnackbar({ text: '網路錯誤，請重新整理。', color: 'red' })
            }
        },
        goPayment(url, code) {
            this.setPaymentUrl({
                paymentUrl: url,
                paymentMode: code
            })
            this.$router.push({name: 'payment'})
            this.modal = false
        },
        delOrderAndGoSearch(text) {
            this.setSnackbar({ text: text, color: 'red' })
            this.deleteOrder()
            this.modal = false
            this.$router.push({name: 'selectReceipt'})
        },
        paymentFailed() {
            this.modal = false
            this.$router.push({name: 'completeTransaction'})
        }
    }
}
</script>
<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="fullWidth"
        height="446.423"
        viewBox="0 0 428 446.423"
        preserveAspectRatio="none">
        <path d="M0,275.816s88.465-19.365,145.688-72.04c49.5-45.563,69-109.645,139.578-160.589S428,0,428,0V446H0Z"
            ransform="translate(0 0.423)"
            :fill="$vuetify.theme.themes.light['main-color']"/>
    </svg>
</template>

<script>
export default {
    props: ['fullWidth']
}
</script>

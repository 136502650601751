<template>
    <v-sheet color="white" :elevation="isMobile ? 4 : 0" width="100%" class="py-5 px-3 mt-3" :class="isMobile ? 'mb-5' : ''">
        <span class="license-plate px-3" v-text="licensePlate" />
        <hr class="my-3" />
        <v-row class="px-3 pb-3" v-if="isMobile">
            <template v-for="(data, index) in info">
                <v-col cols="5" class="text-left pb-0" :class="isPhone ? 'pt-1' : ''" :key="`field-${index}`" v-text="data.field" />
                <v-col cols="7" class="text-right pb-0 text-no-wrap" :class="isPhone ? 'pt-1' : ''" :key="`value-${index}`" v-text="data.value" />
            </template>
        </v-row>
    </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        info: { type: Array, default: () => [] }
    },
    computed: {
        ...mapGetters(['licensePlate']),
        isPhone() {
            return this.$vuetify.breakpoint.xsOnly
        },
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown
        }
    }
}
</script>

<style scoped>
.row {
    font-size: .85rem;
}

.payment-info-shadow, .payment-info-border {
    position: absolute;
    width: 100%;
    height: 102%;
    border-radius: 24px;
}

.payment-info-border {
    z-index: -1;
    top: -9px;
    left: -10px;
    border: 1px solid #c8c2a6a1;
}

.payment-info-shadow {
    z-index: -1;
    top: -14px;
    left: 14px;
    background: #c8c2a6a1;
}

</style>
<template>
    <div class="text-center mt-15">
        <v-btn class="select-btn white--text" color="main-color"
            :block="$vuetify.breakpoint.smAndDown" @click="gotoPayment">
            前往繳費
        </v-btn>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    computed: {
        ...mapGetters(['paymentUrl']),
    },
    created() {
        this.gotoPayment()
    },
    methods: {
        gotoPayment() {
            if (this.$vuetify.breakpoint.smAndDown) {
                window.location.replace(this.paymentUrl.web)

            } else {
                window.location.replace(this.paymentUrl.web)
            }
        }
    }
}
</script>
<template>
    <v-app>
        <v-app-bar app fixed color="navbar" dark v-if="isMobile">
            <div class="d-flex align-center justify-space-between" style="width: 100%">
                <div class="d-flex align-center">
                    <v-btn v-if="showprevBtn"
                        icon x-large color="white" @click="prevPage">
                        <v-icon style="font-size: 60px">mdi-chevron-left</v-icon>
                    </v-btn>
                    <img :src="logo" style="height: 40px" alt="Logo" @click="returnToFrontPageModal = true"/>
                </div>
                <div>
                    <img :src="trademark" style="height: 40px" alt="Trademark" />
                </div>
            </div>
            <v-toolbar-title
                class="letter-4 font-weight-bold ml-4"
                style="font-size: 30px"/>
            <v-spacer />
        </v-app-bar>
        <popup v-model="modal" :title="modalContent.title" width="500" spacing titleCenter
            :btnLeft="modalContent.btnLeft" colorLeft="grey" @clickLeft="modal = false"
            :btnRight="modalContent.btnRight" colorRight="secondary-color" @clickRight="modal = false">
            <div class="text-center letter-3" :class="isXS ? ' text-h7' : ' text-h6'">
                {{ modalContent.text }}
            </div>
        </popup>
        <popup v-model="returnToFrontPageModal" width="500" spacing titleCenter
            btnLeft="取消" colorLeft="grey" @clickLeft="returnToFrontPageModal = false"
            btnRight="確定" colorRight="secondary-color" @clickRight="returnToFrontPage">
            <div class="text-center letter-3" :class="isXS ? ' text-h7' : ' text-h6'">
                確定回到選擇路段頁面？
            </div>
        </popup>

        <v-main>
            <div id="phone-bg" v-if="isMobile">
                <phone-bg :fullWidth="fullWidth" />
            </div>
            <div class="header-stepper" v-if="isMobile && currentPageIndex != steps.length-1">
                <div class="stepper">
                    <div v-for="(info, index) in steps"
                        class="step" :key="info.link"
                        :style="{
                            left: info.left
                        }">
                        <div class="circle" :ref="`step-${index}`" />
                        <p :class="`text${currentPageInfo.link == info.link ? '-selected' : ''}`" v-text="info.name" />
                    </div>
                    <div class="circle-selected" :style="stepLocation" />
                </div>
            </div>
            <v-row
                :style="{
                    height: isMobile ? 'calc(100vh - 60px)' : '100vh',
                    margin: isMobile && currentPageIndex != steps.length-1 ? '50px 0 0 0' : 0,
                }">
                <v-col
                    v-if="!isMobile"
                    :cols="$vuetify.breakpoint.mdAndDown ? 4 : 3"
                    :style="sideBarStyle">
                    <div class="text-center pointer">
                        <img :src="banner" class="banner" alt="Banner" @click="returnToFrontPageModal = true"/>
                    </div>
                    <div class="stepper">
                        <div class="circle-selected" :style="stepLocation" />
                        <template v-for="(info, index) in steps">
                            <div class="step d-flex" :key="info.link">
                                <div class="circle" />
                                <p :class="`text${currentPageInfo.link == info.link ? '-selected' : ''}`" v-text="info.name" />
                            </div>
                            <div class="line" v-if="steps[index + 1]" :key="info.link + '-line'"/>
                        </template>
                    </div>
                    <div class="trademark-logo">
                        <img :src="trademark" style="height: 92px;" alt="Trademark" />
                    </div>
                </v-col>
                <v-col :cols="containerCols" :class="isMobile ? '' : 'px-15'" style="position: relative;">
                    <v-snackbar v-model="snackbar" :color="storeSnackbar.color" top text absolute
                    :style="{'top': isMobile && currentPageIndex != steps.length-1 ? '-50px' : '0'}">
                        <strong v-text="storeSnackbar.text"/>
                        <template v-slot:action="{ attrs }">
                            <v-btn :color="storeSnackbar.color"
                                icon v-bind="attrs" @click="snackbar = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                    </v-snackbar>
                    <v-container ref="container" key="container">
                        <div :class="showprevBtn && !isMobile ? 'd-flex justify-space-between align-center' : ''">
                            <v-btn icon x-large @click="prevPage"
                                v-if="showprevBtn && !isMobile">
                                <v-icon style="font-size: 3em">
                                    mdi-arrow-left
                                </v-icon>
                            </v-btn>
                            <transition :name="`slide-fade-${isNextPage ? 'top' : 'down'}`" appear mode="out-in">
                                <h1 v-if="$route.name != 'completeTransaction'"
                                    :key="currentPageInfo.link" style="color: #6c6c6c !important"
                                    class="text-no-wrap text-center" :class="isMobile ? 'pt-5' : ''">
                                    {{ currentPageInfo.name }}
                                </h1>
                            </transition>
                            <div />
                        </div>
                            <transition :name="`slide-fade-${isNextPage ? 'right' : 'left'}`" appear mode="out-in">
                                <router-view v-slot="{ Component, route }">
                                    <component :is="Component" :key="route.path" :style="routerViewStyle" />
                                </router-view>
                            </transition>
                    </v-container>
                    <div class="next-icon pointer" @click="nextPage" v-if="$route.name != 'completeTransaction'">
                        <p class="currency" v-if="$route.name == 'confirmPayInfo'">$</p>
                        <img v-else :src="downIcon" alt="down-icon" />
                    </div>
                </v-col>
            </v-row>
        </v-main>
        <check-delete-order-modal v-model="checkDeleteOrderModal" />
        <select-payment-modal v-model="selectPaymentModal" />
    </v-app>
</template>

<script>
import popup from "./elements/popup.vue"
import checkDeleteOrderModal from "./elements/check-delete-order-modal.vue"
import selectPaymentModal from "./elements/select-payment-modal.vue"
import phoneBg from "./elements/phone-bg.vue"
import { mapGetters, mapActions } from "vuex"
import logo from '../../assets/image/logo.png'
import trademark from '../../assets/image/trademark.png'
import banner from '../../assets/image/banner.jpg'
import downIcon from '../../assets/image/down-icon.svg'

export default {
    components: {
        popup,
        phoneBg,
        checkDeleteOrderModal,
        selectPaymentModal
    },
    data() {
        return {
            snackbar: false,
            modal: false,
            modalContent: {
                title: "",
                text: "",
                btnLeft: "關閉",
                btnRight: "",
            },
            checkDeleteOrderModal: false,
            selectPaymentModal: false,
            returnToFrontPageModal: false,
            fullWidth: 0,
            isNextPage: false,
            selectSite: null,
            siteDisabled: false,
            containerWidth: 0,
            logo: logo,
            trademark: trademark,
            banner: banner,
            downIcon: downIcon
        }
    },
    computed: {
        ...mapGetters([ "storeSnackbar", "site", "city", "paymentType", "tickets"]),
        steps() {
            return [
                {
                    link: "parking",
                    name: "選擇路段",
                    top: 0,
                    left: '-24px'
                },
                {
                    link: "selectReceipt",
                    name: "搜尋單據",
                    top: 89,
                    left: `${this.isXS ? 12 : 16}vw`
                },
                {
                    link: "confirmPayInfo",
                    name: "確認繳費資訊",
                    top: 180,
                    left: `${this.isXS ? 30.5 : 33.5}vw`
                },
                {
                    link: "payment",
                    name: "支付款項",
                    top: 268,
                    left: `${this.isXS ? 55 : 57}vw`
                },
                {
                    link: "completeTransaction",
                    name: "確認繳費",
                    top: 360,
                    left: 'calc(80vw - 24px)'
                }
            ]
        },
        currentPageInfo() {
            return _.find(this.steps, { link: this.$route.name })
        },
        currentPageIndex() {
            return _.findIndex(this.steps, { link: this.$route.name })
        },
        stepLocation() {
            if (this.fullWidth < 960) {
                const left = this.$refs["step-" + this.currentPageIndex]?.[0].getBoundingClientRect().left
                return {
                    position: "fixed",
                    left: `calc(${left}px - 10vw)`
                }
            } else {
                let top = this.currentPageInfo?.top
                if (this.$vuetify.breakpoint.mdAndDown) {
                    top -= (8 * this.currentPageIndex)
                } else {
                    top -= (4.5 * this.currentPageIndex)
                }
                return {
                    position: "absolute",
                    top: top + "px"
                }
            }
        },
        isXS() {
            return this.$vuetify.breakpoint.xsOnly
        },
        isSM() {
            return this.$vuetify.breakpoint.smOnly
        },
        isMobile() {
            this.getContainerWidth()
            return this.$vuetify.breakpoint.smAndDown
        },
        routerViewStyle() {
            return {
                position: 'absolute',
                width: this.containerWidth - 24 + 'px',
                left: '50%',
                transform: 'translateX(-50%)'
            }
        },
        sideBarStyle() {
            return {
                'background': this.$vuetify.theme.themes.light.navbar,
                'z-index': '1',
                'padding': 0,
                'box-shadow': '-11px 1px 20px 20px #00000036',
                'position': 'relative'
            }
        },
        showprevBtn() {
            return !['parking', 'completeTransaction'].includes(this.$route.name)
        },
        containerCols() {
            if (this.isMobile) {
                return 12
            } else if (this.$vuetify.breakpoint.mdAndDown) {
                return 8
            } else {
                return 9
            }
        }
    },
    watch: {
        snackbar(value) {
            if (!value) {
                this.closeSnackbar(value)
            }
        },
        "storeSnackbar.show"(value) {
            if (value) {
                this.snackbar = true
            }
        },
        "$route.name"(value) {
            if (value != this.steps[0].link && (!this.site || !this.city)) {
                this.reselectRoad()
            }
        }
    },
    mounted() {
        this.fullWidth = window.innerWidth
        window.onresize = () => {
            this.fullWidth = window.innerWidth
            this.getContainerWidth()
        }
    },
    created() {
        this.getContainerWidth()
        this.closeSnackbar()
        this.defaultRoadside()
    },
    methods: {
        ...mapActions(["setSnackbar", "closeSnackbar", 'createOrder']),
        defaultRoadside() {
            this.selectSite = this.$route.query.roadId
        },
        async nextPage() {
            switch (this.$route.name) {
                case "parking": // 選擇路段
                    if (this.paymentType.length == 0) {
                        this.modal = true
                        this.modalContent = {
                            title: "",
                            text: "此路段尚無支付方式。",
                            btnLeft: "關閉",
                            btnRight: "",
                        }
                    } else {
                        this.switchNextPage()
                    }
                    break
                case "selectReceipt": // 搜尋單據
                    if (this.tickets.length == 0) {
                        this.modal = true
                        this.modalContent = {
                            title: "",
                            text: "請選擇至少一張單據。",
                            btnLeft: "關閉",
                            btnRight: "",
                        }
                    } else {
                        await this.createOrder()
                        if (this.tickets.id) {
                            this.switchNextPage()
                        }
                    }
                    break
                case "confirmPayInfo": // 確認繳費資訊
                    this.selectPaymentModal = true
                    break
                case "payment": // 支付款項
                    this.switchNextPage()
                    break
            }
        },
        switchNextPage() {
            this.isNextPage = true
            const index = _.findIndex(this.steps, { link: this.$route.name})
            
            const params = { name: this.steps[index + 1].link }
            if (index == 0) {
               params.query = { roadId: this.selectSite }
            }
            this.$router.push(params)

            setTimeout(() => {
                this.isNextPage = false
            }, 1000)
        },
        prevPage() {
            if (this.$route.name == 'confirmPayInfo') {
                this.checkDeleteOrderModal = true
            } else {
                this.isNextPage = false
                const linkName = this.steps[this.currentPageIndex-1].link
                const params = {name: linkName, query: { roadId: this.selectSite }}
                this.$router.push(params)
            }
        },
        reselectRoad() {
            this.$router.push({ name: this.steps[0].link })
            this.setSnackbar({ text: "請選擇路段" })
        },
        getContainerWidth() {
            this.$nextTick(() => {
                this.containerWidth = this.$refs.container.getBoundingClientRect().width
            })
        },
        returnToFrontPage() {
            const orderResult = window.localStorage.getItem('orderResult')
            if (!orderResult) {
                window.localStorage.setItem('orderResult', 'error')
            }
            this.returnToFrontPageModal = false
            const location = window.location
            let href = location.origin + location.pathname + '#/'
            if (this.selectSite) {
                href += `?roadId=${this.selectSite}`
            }
            location.href = href
        }
    }
}
</script>

<style lang="scss" scoped>
.logo {
    height: 180px;
    margin-top: 10vh;
}

.banner {
    width: -webkit-fill-available;
}

.stepper {
    position: relative;
    top: 35%;
    left: calc((30vw - 345px) / 2);
    margin: 0 auto;
    transform: translateY(-60%);
}

.step {
    display: flex;

    .text, .text-selected {
        margin-top: -4px;
        margin-bottom: 0;
        padding-left: 28px;
        font-weight: bold;
        white-space: nowrap ;
        letter-spacing: .2rem;
        color: var(--v-navbar-text-base);
    }

    .text {
        margin-top: -4px;
        padding-left: 28px;
        font-size: 1.4em;
    }

    .text-selected {
        font-size: 1.5em;
    }
}

.circle {
    width: 25px;
    height: 25px;
    margin-left: 3px;
    background: #ffffff;
    border-radius: 100%;
}

.circle-selected {
    width: 35px;
    height: 35px;
    margin-left: -2px;
    background: var(--v-step-background-base);
    box-shadow: 0 0 7px 1px #00000029;
    border: 5px solid var(--v-step-border-base);
    border-radius: 100%;
}

.line {
    height: 78px;
    margin: -22px 0 0 15px;
    border-left: 2px solid #ffffff;
}

.bronzing-border {
    padding: 1rem;
    border: 1px solid #c8ae67;
    border-radius: 1.5rem;
}

.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding: 0 12px;
}

.container {
    position: relative;
    top: 10%;
    margin: 0 auto;
}

.trademark-logo {
    position: absolute;
    bottom: 38px;
    left: 50%;
    transform: translateX(-50%);
}

@media (min-width: 1904px) {
    .container {
        max-width: 1000px !important;
    }
}

@media (max-width: 1264px) {
    .stepper {
        left: calc((30vw - 182px) / 2);
    }

    .step {
        .text {
            font-size: 1.3em;
        }

        .text-selected {
            font-size: 1.5em;
        }
    }
}

/* Mobile */
@media (max-width: 960px) {
    .container {
        top: 0;
        width: 90%;
        transform: none;
    }

    .header-stepper {
        position: fixed;
        top: 63px;
        width: 100vw;
        height: 60px;
        background-color: #ffffff;
        border-top: .5px solid rgb(255 255 255 / 35%);
    }

    .stepper {
        position: relative;
        top: 40%;
        left: 50vw;
        display: flex;
        width: 80vw;
        border-top: 2px solid #b3b3b3;
        transform: translateX(-64%);
    }

    .step {
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: column;

        .text, .text-selected {
            margin: 8px 0 0 0;
            padding-left: 0;
            font-size: .8rem;
            text-align: justify;
            white-space: nowrap;
            letter-spacing: .15rem;
        }

        .text {
            color: #b3b3b3;
        }

        .text-selected {
            color: #383838;
        }
    }

    .circle {
        width: 15px;
        height: 15px;
        margin-top: -9px;
        margin-left: 0;
        background: #b3b3b3;
    }

    .circle-selected {
        z-index: 1;
        top: -14px;
        width: 25px;
        height: 25px;
        border: 3px solid var(--v-step-border-base);
    }

    .line {
        position: relative;
        left: calc(((100vw / 5) - 10%) / 2);
        width: calc((100vw / 5) - 10%);
        height: 2px;
        margin: 15px 0 0 0;
    }
}

/* phone */
@media (max-width: 540px) {
    .container {
        width: 100%;
    }
}

#phone-bg {
    position: fixed;
    z-index: 0;
    display: flex;
    opacity: .25;
    align-items: flex-end;
    height: 100vh;
}

</style>

<style lang="scss">
.next-icon {
    position: fixed;
    right: calc(37.4vw - 100px);
    bottom: -73px;
    overflow: hidden;
    width: 200px;
    height: 200px;
    padding-top: 40px;
    background: var(--v-main-color-base);
    box-shadow: -1px -5px 7px 0 #00000030;
    border-radius: 64%;
    text-align: center;

    img {
        height: 70px;
    }

    .currency {
        margin-top: -30px;
        font-size: 80px;
        font-weight: bold;
        color: #ffffff;
    }
}

/* Mobile */
@media (max-width: 960px) {
    .next-icon {
        right: calc(50vw - 60px);
        bottom: -40px;
        width: 120px;
        height: 120px;
        padding-top: 20px;

        img {
            height: 44px;
        }

        .currency {
            margin-top: -20px;
            font-size: 58px;
        }
    }
}

</style>
<template>
    <v-dialog v-model="show" :persistent="persistent" :max-width="width" scrollable>
        <v-card rounded="xl">
            <div v-if="title">
                <v-card-title>
                    <h3 class="font-weight-bold" v-text="title"
                        :class="`my-${isMobile ? 3 : 5} ${titleCenter ? 'mx-auto' : ''}`"/>
                </v-card-title>
                <v-btn icon id="icon-close" @click="show = false" v-if="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="dialog-divider" v-if="title" />
            <v-card-text class="py-5 letter-1" :class="textClass">
                <slot />
            </v-card-text>
			<v-card-actions class="d-flex justify-space-around pb-7" v-if="btnLeft">
				<v-btn v-if="btnLeft" :color="colorLeft" outlined elevation="2" @click="$emit('clickLeft')">
					{{btnLeft}}
				</v-btn>
				<v-btn v-if="btnRight" :color="colorRight" outlined elevation="2" @click="$emit('clickRight')">
					<span :class="colorRight + '--text text--darken-1'">{{btnRight}}</span>
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
    model: { prop: 'dialog', event: 'changeDialog'},
    props: {
        dialog: { type: Boolean, default: false },
        persistent: { type: Boolean, default: false },
        spacing: { type: Boolean, default: false },
        width: { type: String, default: '500' },
        close: { type: Boolean, default: false },
        title: { type: String, default: '' },
        titleCenter: { type: Boolean, default: false },
        colorLeft: { type: String, default: 'grey' },
        colorRight: { type: String, default: 'primary' },
        btnLeft: { type: String, default: '' },
        btnRight: { type: String, default: '' }
    },
    computed: {
        show: {
            get() {
                return this.dialog
            },
            set(value) {
                this.$emit('changeDialog', value)
            }
        },
        textClass() {
            let textClass = ''
            textClass += !this.spacing ? 'pa-10': 'px-0'
            textClass += this.title ? '' : ' mt-10'
            textClass += this.$vuetify.breakpoint.smAndDown ? ' px-7' : ''
            return textClass
        },
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown
        }
    }
}
</script>

<style>
.v-card__text {
    z-index: 3;
}

 .dialog-divider {
    height: 3px;
    width: 76px;
    background: var(--v-secondary-color-base);
    border-radius: 15px;
    position: absolute;
    top: 92px;
    left: 50%;
    transform: translateX(-51%);
}

.v-dialog {
    border-radius: 1.3rem;
}

#icon-close {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 24px;
    font-size: 2.2rem !important;
    letter-spacing: 4px !important;
}

.v-dialog:not(.v-dialog--fullscreen) {
    position: relative;
}

@media (max-width: 960px) {
    .dialog-divider {
        top: 80px;
    }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import parking from './components/parking.vue'
import selectReceipt from './components/select-receipt.vue'
import confirmPayInfo from './components/confirm-pay-info.vue'
import payment from './components/payment.vue'
import CompleteTransaction from './components/complete-transaction.vue'

Vue.use(VueRouter)

const routes = [
  { name: 'parking', path: '/', component: parking },
  { name: 'selectReceipt', path: '/select-receipt', component: selectReceipt },
  { name: 'confirmPayInfo', path: '/confirm-pay-info', component: confirmPayInfo },
  { name: 'payment', path: '/payment', component: payment },
  { name: 'completeTransaction', path: '/complete-transaction', component: CompleteTransaction }
]

const router = new VueRouter({
  routes
})

export default router
<template>
    <div>
        <v-select
            :items="sites"
            v-model="selectSite"
            item-text="roadside_name"
            item-value="id"
            :class="selectClass"
            solo hide-details
            no-data-text="尚無路段可選取"
        />
        <div class="select">
            <v-btn
                :color="!isToday ? 'white':'main-color'"
                elevation="2"
                :rounded="!isMobile"
                :disabled="todayPaymentList.length == 0"
                class="select_btn"
                @click="isToday = true">
                <span :class="{ 'navbar-text--text': isToday }">查繳本次路邊停車費<br>（未離場）</span>
            </v-btn>
            <v-btn
                :color="!isToday ? 'main-color' : 'white'"
                elevation="4"
                :rounded="!isMobile"
                :disabled="historyPaymentList.length == 0"
                class="select_btn"
                @click="isToday = false">
                <span :class="{ 'navbar-text--text': !isToday }">查繳其他本市路邊停車費</span>
            </v-btn>
        </div>
        <popup v-model="searchPayment" title="查詢支付方式中"  width="500" spacing titleCenter>
            <div class="text-center">
                <v-progress-circular :size="50" indeterminate/>
            </div>
        </popup>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import popup from "./elements/popup.vue"

export default {
    data() {
        return {
            isToday: true,
            querySite: '',
            selectSite: 0,
            todayPaymentList: [],
            historyPaymentList: [],
            searchPayment: false
        }
    },
    components: { popup },
    computed: {
        ...mapGetters(["sites", "site", "citys", "tickets", "paymentMode", "siteDisabled", "userRoadId"]),
        isXS() {
            return this.$vuetify.breakpoint.xsOnly
        },
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown
        },
        selectClass() {
            let selectClass = 'pointer'
            if (this.siteDisabled) {
                selectClass += ' disable not-allowed'
            }
            selectClass += this.isMobile ? ' mt-5 mb-7' : ` my-${this.isXS ? 5 : 15}`
            return selectClass
        }
    },
    watch: {
        isToday() {
            this.updatePaymentType()
        },
        selectSite() {
            this.searchPaymentType()
        }
    },
    async created() {
        if (this.tickets.id) {
            if (this.paymentMode == 0) {
                this.$router.push({name: 'confirmPayInfo'})
            } else {
                this.$router.push({name: 'completeTransaction'})
            }
        }

        const orderResult = window.localStorage.getItem('orderResult')
        if (orderResult) {
            if (orderResult == 'error') {
                if (this.tickets.id) {
                    this.deleteOrder()
                }
            }
            window.localStorage.removeItem('orderResult')
            this.clearInfo()
        }
        await this.init()
        await this.defaultRoadside()
    },
    methods: {
        ...mapActions(["setRoad", "deleteOrder", "clearInfo", "init", "setSnackbar", "setPaymentType", "setSiteDisabled"]),
        defaultRoadside() {
            if (this.$route.query.roadId) {
                const designatedRoadId = _.find(this.sites, {'code': this.$route.query.roadId})
                if (designatedRoadId) {
                    this.selectSite = designatedRoadId.id
                } else {
                    this.setSnackbar({ text: '不支援此場地，請反映服務人員。', color: 'red' })
                    this.clearInfo()
                }
                this.setSiteDisabled(true)
            } else {
                this.setSiteDisabled(false)
                this.selectSite = this.userRoadId || this.sites[0].id
            }
        },
        async searchPaymentType() {
            this.searchPayment = true
            const [today, history] = await Promise.all([
                this.getPaymentTypeList(this.citys[this.selectSite].today, 'today'),
                this.getPaymentTypeList(this.citys[this.selectSite].history, 'history')
            ])
            if (today && history) {
                this.searchPayment = false
            }
            if (this.isToday) {
                if (this.todayPaymentList.length == 0) {
                    this.isToday = false
                }
            } else {
                if (this.historyPaymentList.length == 0) {
                    this.isToday = true
                }
            }
            this.updatePaymentType()
        },
        updatePaymentType() {
            this.setRoad({ id: this.selectSite, isToday: this.isToday })
            if (this.isToday) {
                this.setPaymentType(this.todayPaymentList)
            } else {
                this.setPaymentType(this.historyPaymentList)
            }
        },
        async getPaymentTypeList(site, type) {
            const typeChinese  = type == 'today' ? '今日' : '歷史'
            if (site) {
                try {
                    const response = await axios.post('payment-list', { site: site })
                    switch (response.data.code) {
                        case 20000: {
                            const paymentType = response.data.payload.type
                            if (!paymentType || paymentType.length == 0) {
                                this.setPaymentTypeData([], type)
                            }else {
                                this.setPaymentTypeData(paymentType, type)
                            }
                            return true
                        }
                        case 40000:
                            this.setSnackbar({ text: response.data.message, color: 'red' })
                            return false
                        default:
                            this.setSnackbar({ text: typeChinese + '支付方式抓取失敗，請重新嘗試選擇路段', color: 'red' })
                            this.setPaymentTypeData([], type)
                            return true
                    }
                } catch (e) {
                    this.setSnackbar({ text: typeChinese + '支付方式抓取失敗，請重新嘗試選擇路段', color: 'red' })
                    this.setPaymentTypeData([], type)
                    return true
                }
            } else {
                this.setPaymentTypeData([], type)
                return true
            }

        },
        setPaymentTypeData(data, type) {
            if (type == 'today') {
                this.todayPaymentList = data
            } else {
                this.historyPaymentList = data
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #e7e7e7 !important;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),
    0 2px 2px 0 rgba(0,0,0,.14),
    0 1px 5px 0 rgba(0,0,0,.12);

    .v-btn__content span {
        color: #4e4e4e !important;
    }
}

.select {
    display: flex;
    justify-content: space-around;
    padding: 0 20px;

    &_btn {
        width: 40% !important;
        height: 24vh !important;
        background: white;
        border-radius: 20px;

        span {
            font-size: 1.6rem;
            font-weight: bold;
            letter-spacing: .2em;
        }
    }
}

/* Mobile */
@media (max-width: 1450px) {
    .select {
        flex-direction: column;
        padding: 60px 35px;

        &_btn {
            width: 100% !important;
            height: 18vh !important;
            margin-bottom: 40px!important;

            span {
                font-weight: bold;
                letter-spacing: .25em;
            }
        }
    }
}

// Phone
@media (max-width: 540px) {
    .select {
        padding: 0;

        &_btn {
            margin-bottom: 28px!important;

            span {
                font-size: 1.2rem;
                letter-spacing: .2em;
            }
        }
    }
}

</style>

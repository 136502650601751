<template>
    <div>
        <div :class="isPhone ? '' : 'd-flex justify-space-between'">
            <div style="position: relative;" class="pointer" v-if="!isPhone" @click="selectAll">
                <select-all/>
                <span class="text-no-wrap navbar-text--text" style="position: absolute; top: 55px; left: 55px;">全選</span>
            </div>
            <div class="d-flex align-center mt-5">
                <v-text-field solo class="mr-5" :class="currentRoadside.isDash ? '' : 'pb-5'" style="width: 350px;" ref="licensePlate" label="車牌號碼"
                    :hide-details="!currentRoadside.isDash" inputmode="search"
                     placeholder="車牌號碼" hint="車牌號碼須包含''-''" v-model="licensePlate"
                    @keydown.enter="search">
                    <template v-slot:prepend-inner>
                        <v-menu offset-y rounded="xl">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon v-text="'mdi-ideogram-cjk-variant'" />
                                </v-btn>
                            </template>
                            <v-card height="55" width="290" rounded="xl" class="py-3 px-5" >
                                <v-chip outlined class="mx-1" @click="licensePlate = licensePlate + i"
                                    v-for="i in ['試', '軍', '臨', '外', '使']" :key="i">
                                    {{ i }}
                                </v-chip>
                            </v-card>
                        </v-menu>
                    </template>
                </v-text-field>
                <v-btn color="secondary-color" outlined icon large elevation="3"
                    :class="currentRoadside.isDash ? 'mb-12' : 'mb-5'" @click="search">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </div>
            <div v-if="!isPhone" style="width: 36px;"/>
        </div>
        <receipt isSelect :selected="selected" :list="receipts" @selectAll="selectAll" :pc-height="isTablet ? 620 : 650" :hint-text="hintText"/>
        <info-bar-bronzing
            v-if="!isPhone" class="ml-auto mt-4" :info="paymentInfo"
            :width="`calc( 35vw ${$vuetify.breakpoint.mdAndDown ? '' : ' / 2 + 3vw'} )`" />
        <popup v-model="modal" :title="modalContent.title"  width="500" spacing titleCenter
            :btnLeft="modalContent.btnLeft" colorLeft="grey" @clickLeft="modalClick"
            :btnRight="modalContent.btnRight" colorRight="secondary-color" @clickRight="modal = false">
            <div v-if="modalContent.title == '搜尋中'" class="text-center">
                <v-progress-circular :size="50" indeterminate/>
            </div>
            <div v-else class="text-center letter-3" :class="isTablet ? ' text-h7' : ' text-h6'">
                {{ modalContent.text }}
            </div>
        </popup>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import receipt from "./elements/receipt.vue"
import selectAllSvg from "./elements/select-all.vue"
import infoBarBronzing from "./elements/info-bar-bronzing.vue"
import popup from "./elements/popup.vue"

export default {
    components: {
        popup,
        receipt,
        'select-all': selectAllSvg,
        infoBarBronzing
    },
    data() {
        return {
            licensePlate: "",
            selected: [],
            receipts: [],
            modal: false,
            modalContent: {
                title: "",
                text: "",
                btnLeft: "關閉",
                btnRight: ""
            },
            axiosSource: null,
            hintText: '尚無單據'
        }
    },
    watch: {
        licensePlate: {
            handler(newValue, oldValue) {
                if (!/^[a-zA-Z0-9-試軍臨外使]*$/.test(newValue)) {
                    this.licensePlate = oldValue
                } else {
                    this.licensePlate = newValue.toUpperCase()
                }
                this.$refs.licensePlate.lazyValue = this.licensePlate
            },
        },
        modal(value) {
            if (!value && this.modalContent.title == '搜尋中') {
                this.searchStop()
            }
        },
        selected(tickets) {
            this.setTickets({
                licensePlate: this.licensePlate,
                tickets: tickets,
            })
        }
    },
    created() {
        this.selected = []
        this.licensePlate = this.$store.state.licensePlate
        this.axiosSource = axios.CancelToken.source()
        if (this.licensePlate) {
            this.search()
        }
    },
    computed: {
        ...mapGetters(["currentRoadside", "citys", "roadsideName"]),
        isPhone() {
            return this.$vuetify.breakpoint.xsOnly
        },
        isTablet() {
            return this.$vuetify.breakpoint.mdAndDown
        },
        paymentInfo() {
            const total_fee = this.receipts.filter(item => this.selected.includes(item.id))
            return [
                {field: '路段名稱', value: this.roadsideName},
                {field: '總筆數', value: this.selected.length + ' 筆'},
                {field: '總金額', value: '$ ' + _.sum(total_fee.map(item => item.actual_fee)).toLocaleString()},
            ]
        }
    },
    methods: {
        ...mapActions(["setSnackbar", "setTickets"]),
        async search() {
            this.modal = true
             if (this.licensePlate) {
                if (this.currentRoadside.isDash && this.licensePlate.search('-') < 0) {
                    this.modalContent = {
                        title: "格式錯誤",
                        text: "車牌號碼須包含 ' - ' ",
                        btnLeft: "確定",
                        btnRight: ""
                    }
                } else {
                    this.modalContent = {
                        title: "搜尋中",
                        text: "",
                        btnLeft: "取消",
                        btnRight: ""
                    }
                    const params = {
                        city: this.currentRoadside.city,
                        license_plate: this.licensePlate,
                    }
                    const cancelToken = { cancelToken: this.axiosSource.token }
                    try {
                        const response = await axios.post('order/search', params, cancelToken)
                        switch (response.data.code) {
                            case 20000:
                                this.receipts = response.data.payload
                                this.hintText = '尚無可繳費單據'
                                this.selected = []
                                this.modal = false
                                break
                            case 40000:
                                this.setSnackbar({ text: response.data.message, color: 'red' })
                                this.modal = false
                                break
                        }
                    } catch (error) {
                        this.modal = false
                    }
                }
            } else {
                this.modalContent = {
                    title: "車牌號碼為空",
                    text: "請填寫車牌號碼在搜尋單據",
                    btnLeft: "確定",
                    btnRight: ""
                }
            }
        },
        modalClick() {
            if (this.modalContent.title == '搜尋中') {
                this.searchStop()
            } else {
                this.modal = false
            }
        },
        searchStop() {
            this.axiosSource.cancel()
            this.axiosSource = axios.CancelToken.source()
            this.modal = false
        },
        selectAll() {
            if (this.selected.length == this.receipts.length) {
                this.selected = []
            } else {
                this.selected = this.receipts.map(item => item.id)
            }
        }
    }
}
</script>

<style>
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
    padding-right: 13px;
    margin-left: -8px;
}
</style>
<template>
    <div>
        <info-bar :info="paymentInfo"/>
        <receipt :list="tickets.tickets" :selected="[]" :pc-height="isTablet ? 450 : 650" :phone-add-height="10">
            <v-btn color="#D6914A" outlined @click="checkDeleteOrderModal = true"> 取消訂單 </v-btn>
        </receipt>
        <info-bar-bronzing
            class="mt-5 ml-auto" v-if="!isTablet" :info="paymentInfo"
            :width="`calc( 27vw ${$vuetify.breakpoint.lgAndDown ? '' : '- 6vw'} )`" />
        <check-delete-order-modal v-model="checkDeleteOrderModal" />
        <select-payment-modal v-model="selectPaymentModal" />
        <popup v-model="forceDeleteOrderModal" title="訂單已逾期"  width="500" spacing titleCenter persistent
            btnLeft="確定" colorLeft="secondary-color" @clickLeft="clearOrder">
            <div class="text-center letter-3 mx-7" :class="`text-h${isTablet ? 7 : 6}`">
                已在此頁面閒置15分鐘，系統將取消訂單並導向至搜尋單據頁面。
            </div>
        </popup>
    </div>
</template>

<script>
import receipt from "./elements/receipt.vue"
import checkDeleteOrderModal from "./elements/check-delete-order-modal.vue"
import selectPaymentModal from "./elements/select-payment-modal.vue"
import infoBar from "./elements/info-bar.vue"
import infoBarBronzing from "./elements/info-bar-bronzing.vue"
import popup from "./elements/popup.vue"
import { mapGetters, mapActions } from "vuex"
import { setInterval, clearInterval } from 'timers'

export default {
    data() {
        return {
            checkDeleteOrderModal: false,
            selectPaymentModal: false,
            orderOverdueTime: null,
            remainingTime: null,
            forceDeleteOrderModal: false,
            timing: null,
        }
    },
    components: { receipt, checkDeleteOrderModal, selectPaymentModal, infoBar, infoBarBronzing, popup },
    mounted() {
        this.remainingTime = window.localStorage.getItem('remainingTime') || moment().add(15, 'minute')
        window.localStorage.setItem('remainingTime', this.remainingTime)
        this.timing = setInterval(this.timedOrderCancellation, 1000)
    },
    created() {
        if (!this.tickets.id) {
            this.$router.push({ name: 'selectReceipt'})
        }
    },
    computed: {
        ...mapGetters(["roadsideName", "tickets", "build_time"]),
        isPhone() {
            return this.$vuetify.breakpoint.xsOnly
        },
        isTablet() {
            return this.$vuetify.breakpoint.smAndDown
        },
        paymentInfo() {
            const info = [
                { field: '路段', value: this.roadsideName },
                { field: '訂單成立時間', value: this.build_time },
                { field: '訂單逾期時間', value: this.time}
            ]
            if (this.$vuetify.breakpoint.smAndUp) {
                info.push({ field: '總金額', value: '$ ' + this.tickets.total_fee.toLocaleString() })
            }
            return info
        },
        time() {
            const remainSeconds = Math.max(this.orderOverdueTime, 0)

            const min = Math.floor(remainSeconds / 60).toString().padStart(2, '0')
            const sec = (remainSeconds % 60).toString().padStart(2, '0')

            return `${min}:${sec}`
        }
    },
    methods: {
        ...mapActions(['deleteOrder']),
        timedOrderCancellation() {
            const toNow = moment()
            this.orderOverdueTime = moment(this.remainingTime).diff(toNow, 'seconds')
            if (this.orderOverdueTime < 0) {
                this.forceDeleteOrderModal = true
            }
        },
        async clearOrder() {
            this.endCountdown()
            await this.deleteOrder()
            this.forceDeleteOrderModal = false
            if (this.tickets.length == 0) {
                this.$router.push({name: 'selectReceipt'})
            }
        },
        endCountdown() {
            window.localStorage.removeItem('remainingTime')
            this.orderOverdueTime = null
            this.remainingTime = null
            clearInterval(this.timing)
        }
    },
    beforeDestroy () {
        this.endCountdown()
    }
}
</script>

<style>
.license-plate {
    font-size: 2.2em;
    font-weight: bold;
    letter-spacing: 0.1em;
    line-height: 1.2em;
}
@media (max-width: 960px) {
    .license-plate {
        font-size: 1.5em;
    }
}
</style>

import index from './components/index.vue';

require('./bootstrap');

window.Vue = require('vue');

import vuetify from './vuetify.js';
import router from './routes';
import store from './store';
import '../assets/css/_self.css';

const app = new Vue({
    el: '#app',
    vuetify,
    store,
    router,
    render: h => h(index)
}).$mount('#app');

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: {
          customProperties: true,
        },
        themes: {
            light: {
                'main-color': process.env.MIX_MAIN_COLOR,
                'secondary-color': process.env.MIX_SECONDARY_COLOR,
                navbar: process.env.MIX_NAVBAR,
                'navbar-text': process.env.MIX_NAVBAR_TEXT,
                card: process.env.MIX_CARD,
                'step-background': process.env.MIX_STEP_BACKGROUND,
                'step-border': process.env.MIX_STEP_BORDER,
                bronzing: '#a59963',
                error: '#A42424'
            }
        },
    },
})
